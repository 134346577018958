import useGridData from './useGridData'

const columns = [
  { key: 'id', label: 'Id' },
  { key: 'locationId', label: 'Location Id' },
  { key: 'agencyKey', label: 'Agency Key' },
  { key: 'siteKey', label: 'Site Key' },
  { key: 'name', label: 'Name' },
  { key: 'bldgLine', label: 'Bldg Line' },
  { key: 'address1', label: 'Address1' },
  { key: 'address2', label: 'Address2' },
  { key: 'city', label: 'City' },
  { key: 'state', label: 'State' },
  { key: 'zipCode', label: 'Zip Code' },
  { key: 'county', label: 'County' },
  { key: 'country', label: 'Country' },
  { key: 'confidential', label: 'Confidential' },
  { key: 'address1Mail', label: 'Address1 Mail' },
  { key: 'address2Mail', label: 'Address2 Mail' },
  { key: 'cityMail', label: 'City Mail' },
  { key: 'stateMail', label: 'State Mail' },
  { key: 'zipCodeMail', label: 'Zip Code Mail' },
  { key: 'countyMail', label: 'County Mail' },
  { key: 'countryMail', label: 'Country Mail' },
  { key: 'confidentialMail', label: 'Confidential Mail' },
  { key: 'administrator', label: 'Administrator' },
  { key: 'administratorTitle', label: 'Administrator Title' },
  { key: 'administratorPhone', label: 'Administrator Phone' },
  { key: 'administratorExt', label: 'Administrator Ext' },
  { key: 'administratorEmail', label: 'Administrator Email' },
  { key: 'contact1Name', label: 'Contact1 Name' },
  { key: 'contact1Title', label: 'Contact1 Title' },
  { key: 'contact1Phone', label: 'Contact1 Phone' },
  { key: 'contact1Ext', label: 'Contact1 Ext' },
  { key: 'contact1Email', label: 'Contact1 Email' },
  { key: 'contact2Name', label: 'Contact2 Name' },
  { key: 'contact2Title', label: 'Contact2 Title' },
  { key: 'contact2Phone', label: 'Contact2 Phone' },
  { key: 'contact2Ext', label: 'Contact2 Ext' },
  { key: 'contact2Email', label: 'Contact2 Email' },
  { key: 'contact3Name', label: 'Contact3 Name' },
  { key: 'contact3Title', label: 'Contact3 Title' },
  { key: 'contact3Phone', label: 'Contact3 Phone' },
  { key: 'contact3Ext', label: 'Contact3 Ext' },
  { key: 'contact3Email', label: 'Contact3 Email' },
  { key: 'active', label: 'Active' },
  { key: 'email', label: 'Email' },
  { key: 'url', label: 'Url' },
  { key: 'agencyType', label: 'Agency Type' },
  { key: 'facilityType', label: 'Facility Type' },
  { key: 'notInDirectory', label: 'Not In Directory' },
  { key: 'notInWebSite', label: 'Not In Web Site' },
  { key: 'localLocation', label: 'Local Location' },
  { key: 'priority', label: 'Priority' },
  { key: 'publicTransAccess', label: 'Public Trans Access' },
  { key: 'taxId', label: 'Tax Id' },
  { key: 'yearInc', label: 'Year Inc' },
  { key: 'irsStatus', label: 'Irs Status' },
  { key: 'altNbr1', label: 'Alt Nbr1' },
  { key: 'altNbr2', label: 'Alt Nbr2' },
  { key: 'surveyPassword', label: 'Survey Password' },
  { key: 'ebSend', label: 'Eb Send' },
  { key: 'formalUpdate', label: 'Formal Update' },
  { key: 'staffAssigned', label: 'Staff Assigned' },
  { key: 'added', label: 'Added' },
  { key: 'addedBy', label: 'Added By' },
  { key: 'updatedBy', label: 'Updated By' },
  { key: 'recordOwner', label: 'Record Owner' },
  { key: 'siteAccessibility', label: 'Site Accessibility' },
  { key: 'siteAkaName', label: 'Site Aka Name' },
  { key: 'sitePhone1', label: 'Site Phone1' },
  { key: 'sitePhone2', label: 'Site Phone2' },
  { key: 'sitePhone3', label: 'Site Phone3' },
  { key: 'sitePhone4', label: 'Site Phone4' },
  { key: 'sitePhone5', label: 'Site Phone5' },
  { key: 'sitePhone6', label: 'Site Phone6' },
  { key: 'sitePhone7', label: 'Site Phone7' },
  { key: 'sitePhone8', label: 'Site Phone8' },
  { key: 'sitePhone9', label: 'Site Phone9' },
  { key: 'sitePhone10', label: 'Site Phone10' },
  { key: 'siteHours', label: 'Site Hours' },
  { key: 'travelInstructions', label: 'Travel Instructions' },
  { key: 'createdAt', label: 'Created At', type: 'DATETIME' },
  { key: 'updatedAt', label: 'Updated At', type: 'DATETIME' }
]

export default function useSiteGridData(cache: Boolean = true) {
  return useGridData('/site', columns, cache)
}
