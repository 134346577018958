import useGridData from './useGridData'

const columns = [
  { key: 'id', label: 'Id' },
  { key: 'sender', label: 'Sender' },
  { key: 'recipient', label: 'Recipient' },
  { key: 'subject', label: 'Subject' },
  { key: 'emailOrTextContent', label: 'Email Or Text Content' },
  { key: 'emailOrText', label: 'Email Or Text' },
  { key: 'dateSent', label: 'Date Sent', type: 'DATETIME' },
  { key: 'staffId', label: 'Staff Id' },
  { key: 'recordOwner', label: 'Record Owner' },
  { key: 'createdAt', label: 'Created At', type: 'DATETIME' },
  { key: 'updatedAt', label: 'Updated At', type: 'DATETIME' }
]

export default function useMessageGridData(cache: Boolean = true) {
  return useGridData('/message', columns, cache)
}
