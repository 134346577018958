// @flow

import './Card.scss'

import React from 'react'

type Props = {
  title: String,
  children: any
}

export default function Card(props: Props) {
  return (
    <div className="Card">
      <div className="title">{props.title}</div>
      <div className="body">{props.children}</div>
    </div>
  )
}
