import './Summary.scss'

import React from 'react'
import { useCompletedContactSummaryData } from '../../../../hooks'
import { Card, Grid } from '../../../layout'

const totalColumns = [
  { key: 'date', label: 'Date' },
  { key: 'count', label: 'Count' }
]

const mediaTypeColumns = [
  { key: 'mediaTypeName', label: 'Media Type' },
  { key: 'count', label: 'Count' }
]

export default function Summary() {
  const data = useCompletedContactSummaryData()

  return (
    <div className="CompletedContactDashboard_Summary">
      {data ? (
        <>
          <Card title="Total records grouped by month">
            <Grid columns={totalColumns} rows={data.totalsByMonth} />
          </Card>
          <Card title="Number of records by media type last month">
            <Grid columns={mediaTypeColumns} rows={data.monthData} />
          </Card>
          <Card title="Number of records by media type this year">
            <Grid columns={mediaTypeColumns} rows={data.yearData} />
          </Card>
        </>
      ) : (
        'Loading...'
      )}
    </div>
  )
}
