import './Main.scss'

import React from 'react'
import { Route } from 'react-router-dom'
import Home from '../../home/Home'
import Tabs, { Tab, RTMTab, InContactTab } from '../tabs/Tabs'
import Settings from '../../settings/Settings'
import About from '../../about/About'
import ContactDashboard from '../../dashboards/contactdashboard/ContactDashboard'
import ContactActionDashboard from '../../dashboards/contactactiondashboard/ContactActionDashboard'
import SiteDashboard from '../../dashboards/sitedashboard/SiteDashboard'
import CompletedContactDashboard from '../../dashboards/completedcontactdashboard/CompletedContactDashboard'
import ContactUserDataDashboard from '../../dashboards/contactuserdatadashboard/ContactUserDataDashboard'
import MessageDashboard from '../../dashboards/messagedashboard/MessageDashboard'
import AgentDashboard from '../../dashboards/agentdashboard/AgentDashboard'
import SkillDashboard from '../../dashboards/skilldashboard/SkillDashboard'
import MediaTypeDashboard from '../../dashboards/mediatypedashboard/MediaTypeDashboard'

function Main() {
  return (
    <div className="Main">
      <div className="header row">
        <Tabs scroll>
          <Tab to="/" exact>
            Home
          </Tab>
          <RTMTab to="/contact">Contact</RTMTab>
          <RTMTab to="/contactaction">ContactAction</RTMTab>
          <RTMTab to="/contactuserdata">ContactUserData</RTMTab>
          <RTMTab to="/site">Site</RTMTab>
          <RTMTab to="/message">Messages</RTMTab>
          <InContactTab to="/completedcontact">CompletedContact</InContactTab>
          <InContactTab to="/agent">Agents</InContactTab>
          <InContactTab to="/skill">Skills</InContactTab>
          <InContactTab to="/mediatype">Media Types</InContactTab>
          <div className="divider" />
          <Tab to="/settings">Settings</Tab>
          <Tab to="/about">About</Tab>
        </Tabs>
      </div>

      <div className="content">
        <Route exact path="/">
          <Home />
        </Route>

        <Route path="/contact">
          <ContactDashboard />
        </Route>
        <Route path="/contactaction">
          <ContactActionDashboard />
        </Route>
        <Route path="/contactuserdata">
          <ContactUserDataDashboard />
        </Route>
        <Route path="/site">
          <SiteDashboard />
        </Route>
        <Route path="/message">
          <MessageDashboard />
        </Route>
        <Route path="/completedcontact">
          <CompletedContactDashboard />
        </Route>
        <Route path="/agent">
          <AgentDashboard />
        </Route>
        <Route path="/skill">
          <SkillDashboard />
        </Route>
        <Route path="/mediatype">
          <MediaTypeDashboard />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/about">
          <About />
        </Route>
      </div>
    </div>
  )
}

export default Main
