import './Summary.scss'

import React from 'react'
import { useContactUserDataSummaryData } from '../../../../hooks'
import { Card, Grid } from '../../../layout'

const totalColumns = [
  { key: 'question', label: 'Question' },
  { key: 'count', label: 'Count' }
]

export default function Summary() {
  const data = useContactUserDataSummaryData()

  return (
    <div className="ContactUserDataDashboard_Summary">
      {data ? (
        <>
          <Card title="Total records grouped by question">
            <Grid columns={totalColumns} rows={data.totalsByQuestion} />
          </Card>
        </>
      ) : (
        'Loading...'
      )}
    </div>
  )
}
