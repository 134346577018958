import React from 'react'
import { Grid } from '../../../layout'
import { useCompletedContactGridData } from '../../../../hooks'

export default function RawData() {
  const data = useCompletedContactGridData()

  return (
    <div className="CompletedContactDashboard_RawData">
      {data ? (
        <Grid {...data} download />
      ) : (
        'Loading CompletedContactDashboard...'
      )}
    </div>
  )
}
