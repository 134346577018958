import React from 'react'
import { Grid } from '../../../layout'
import { useMessageGridData } from '../../../../hooks'

export default function RawData() {
  const data = useMessageGridData()

  return (
    <div className="MessageDashboard_RawData">
      {data ? <Grid {...data} download /> : 'Loading MessageDashboard...'}
    </div>
  )
}
