import { useState, useEffect } from 'react'
import axios from 'axios'

export let _cachedData = {}

export default function useSummaryData(
  endpoint: string,
  cache: Boolean = true
) {
  const [data, setData] = useState(null)

  useEffect(() => {
    if (cache && _cachedData[endpoint]) {
      setData(_cachedData[endpoint])
    } else {
      axios.get(endpoint).then(res => {
        setData(res.data)
        if (cache) _cachedData[endpoint] = res.data
      })
    }
  }, [endpoint, cache])

  return data
}
