/* eslint-disable react-hooks/exhaustive-deps */

import './DownloadButton.scss'

import React, { useEffect, useContext } from 'react'
import { Parser } from 'json2csv'
import { TabDownloadButtonContext } from '../../dashboards/context'

type Column = {
  key: String,
  label: String,
  type?: 'DATE' | 'DATETIME' | 'PHONE' | null,
  render?: Function
}

type Props = {
  rows: Object[],
  columns?: Column[]
}

export default function DownloadButton(props: Props) {
  const setDownloadButton = useContext(TabDownloadButtonContext)

  const onClick = () => {
    const { rows, columns } = props
    try {
      const fields = columns.map(c => c.key)
      const parser = new Parser({ fields })
      const csv = parser.parse(rows)
      const blob = new Blob([csv], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'download.csv'
      a.style = 'position: fixed; left: 10000px;'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    setDownloadButton(
      <button className="DownloadButton primary" onClick={onClick}>
        Download
      </button>
    )

    return function unmountDownloadButton() {
      setDownloadButton(null)
    }
  }, [])

  return null
}
