import useGridData from './useGridData'

const columns = [
  { key: 'id', label: 'Id' },
  { key: 'name', label: 'Name' },
  { key: 'createdAt', label: 'Created At', type: 'DATETIME' },
  { key: 'updatedAt', label: 'Updated At', type: 'DATETIME' }
]

export default function useMediaTypeGridData(cache: Boolean = true) {
  return useGridData('/mediatype', columns, cache)
}
