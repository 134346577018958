import './Login.scss'

import React, { useState } from 'react'
import axios from 'axios'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [saving, setSaving] = useState(false)

  const isValid = !!email.trim() && !!password.trim()

  const onSubmit = async (e: Event) => {
    e.preventDefault()
    setSaving(true)
    if (error) {
      setError(null)
    }

    try {
      const res = await axios.post('/login', { email, password })
      console.log(res)
      window.location.reload()
    } catch (err) {
      console.error(err)
      setError(err.message)
      setSaving(false)
    }
  }

  return (
    <div className="Login">
      <form className="sm" onSubmit={onSubmit}>
        <h2>Login</h2>
        {error ? <h5>{error}</h5> : null}
        <div className="row-c">
          <label>Email</label>
          <input
            type="email"
            autoCapitalize="off"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="row-c">
          <label>Password</label>
          <input
            type="password"
            autoCapitalize="off"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <div className="row-c">
          <button
            className="primary"
            type="submit"
            disabled={!isValid || saving}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}
