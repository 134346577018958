import useGridData from './useGridData'

const columns = [
  { key: 'id', label: 'Id' },
  { key: 'abandoned', label: 'Abandoned' },
  { key: 'abandonSeconds', label: 'Abandon Seconds' },
  { key: 'ACWSeconds', label: 'Acw Seconds' },
  { key: 'agentId', label: 'Agent Id' },
  { key: 'agentSeconds', label: 'Agent Seconds' },
  { key: 'callbackTime', label: 'Callback Time' },
  { key: 'campaignId', label: 'Campaign Id' },
  { key: 'campaignName', label: 'Campaign Name' },
  { key: 'confSeconds', label: 'Conf Seconds' },
  { key: 'contactId', label: 'Contact Id' },
  { key: 'contactStart', label: 'Contact Start', type: 'DATETIME' },
  { key: 'dateACWWarehoused', label: 'Date Acw Warehoused', type: 'DATETIME' },
  {
    key: 'dateContactWarehoused',
    label: 'Date Contact Warehoused',
    type: 'DATETIME'
  },
  { key: 'dispositionNotes', label: 'Disposition Notes' },
  { key: 'firstName', label: 'First Name' },
  { key: 'fromAddr', label: 'From Addr', type: 'PHONE' },
  { key: 'holdCount', label: 'Hold Count' },
  { key: 'holdSeconds', label: 'Hold Seconds' },
  { key: 'inQueueSeconds', label: 'In Queue Seconds' },
  { key: 'isLogged', label: 'Is Logged' },
  { key: 'isOutbound', label: 'Is Outbound' },
  { key: 'isRefused', label: 'Is Refused' },
  { key: 'isShortAbandon', label: 'Is Short Abandon' },
  { key: 'isTakeover', label: 'Is Takeover' },
  { key: 'lastName', label: 'Last Name' },
  { key: 'lastUpdateTime', label: 'Last Update Time', type: 'DATETIME' },
  { key: 'masterContactId', label: 'Master Contact Id' },
  { key: 'mediaType', label: 'Media Type' },
  { key: 'mediaTypeName', label: 'Media Type Name' },
  { key: 'pointOfContactId', label: 'Point Of Contact Id' },
  { key: 'pointOfContactName', label: 'Point Of Contact Name' },
  { key: 'postQueueSeconds', label: 'Post Queue Seconds' },
  { key: 'preQueueSeconds', label: 'Pre Queue Seconds' },
  { key: 'primaryDispositionId', label: 'Primary Disposition Id' },
  { key: 'refuseReason', label: 'Refuse Reason' },
  { key: 'refuseTime', label: 'Refuse Time' },
  { key: 'releaseSeconds', label: 'Release Seconds' },
  { key: 'routingTime', label: 'Routing Time' },
  { key: 'secondaryDispositionId', label: 'Secondary Disposition Id' },
  { key: 'serviceLevelFlag', label: 'Service Level Flag' },
  { key: 'skillId', label: 'Skill Id' },
  { key: 'skillName', label: 'Skill Name' },
  { key: 'teamId', label: 'Team Id' },
  { key: 'teamName', label: 'Team Name' },
  { key: 'toAddr', label: 'To Addr' },
  { key: 'totalDurationSeconds', label: 'Total Duration Seconds' },
  { key: 'transferIndicatorId', label: 'Transfer Indicator Id' },
  { key: 'transferIndicatorName', label: 'Transfer Indicator Name' },
  { key: 'isAnalyticsProcessed', label: 'Is Analytics Processed' },
  {
    key: 'analyticsProcessedDate',
    label: 'Analytics Processed Date',
    type: 'DATETIME'
  },
  { key: 'createdAt', label: 'Created At', type: 'DATETIME' },
  { key: 'updatedAt', label: 'Updated At', type: 'DATETIME' }
]

export default function useCompletedContactGridData(cache: Boolean = true) {
  return useGridData('/completedcontact', columns, cache)
}
