import './Summary.scss'

import React from 'react'
import { useHistory, useRouteMatch, Route } from 'react-router-dom'
import { useContactActionSummaryData } from '../../../../hooks'
import { Card, Grid } from '../../../layout'
import ByWeek from '../views/ByWeek'
import ByMonth from '../views/ByMonth'

const totalColumns = [
  { key: 'date', label: 'Date' },
  { key: 'count', label: 'Count' }
]

const reasonColumns = [
  { key: 'actionReason', label: 'Reason' },
  { key: 'count', label: 'Count' }
]

const zipColumns = [
  { key: 'searchZipCode', label: 'Search Zip Code' },
  { key: 'count', label: 'Count' }
]

const actionServiceTermColumns = [
  { key: 'actionServiceTerm', label: 'Action Service Term' },
  { key: 'date', label: 'Date' },
  { key: 'count', label: 'Count' }
]

const actionServiceTermByWeekColumns = [
  { key: 'actionServiceTerm', label: 'Action Service Term' },
  { key: 'searchCounty', label: 'County' },
  { key: 'date', label: 'Week start date' },
  { key: 'count', label: 'Count' }
]

export default function Summary() {
  const history = useHistory()
  const match = useRouteMatch()
  const data = useContactActionSummaryData()

  return (
    <>
      <Route exact path={match.path}>
        <div className="ContactActionDashboard_Summary">
          {data ? (
            <>
              <Card title="Total records grouped by month">
                <Grid columns={totalColumns} rows={data.totalsByMonth} />
              </Card>
              <Card title="Total records grouped by reason">
                <Grid columns={reasonColumns} rows={data.totalsByReason} />
              </Card>
              <Card title="Number of records by zip code last month">
                <Grid columns={zipColumns} rows={data.monthData} />
              </Card>
              <Card title="Number of records by zip code this year">
                <Grid columns={zipColumns} rows={data.yearData} />
              </Card>
              <Card title="Number of records by search term by month">
                <Grid
                  columns={actionServiceTermColumns}
                  rows={data.totalsByActionServiceTermByMonth}
                  onRowClick={row => {
                    history.push(
                      `${match.url}/by-month/${encodeURIComponent(
                        JSON.stringify(row)
                      )}`
                    )
                  }}
                />
              </Card>
              <Card title="Number of records by search term by county by week">
                <Grid
                  columns={actionServiceTermByWeekColumns}
                  rows={data.totalsByActionServiceTermByCountyByWeek}
                  onRowClick={row => {
                    history.push(
                      `${match.url}/by-week/${encodeURIComponent(
                        JSON.stringify(row)
                      )}`
                    )
                  }}
                />
              </Card>
            </>
          ) : (
            'Loading...'
          )}
        </div>
      </Route>
      <Route
        path={`${match.path}/by-week/:row`}
        render={({ match }) => {
          if (!data) {
            return null
          }

          const row = JSON.parse(decodeURIComponent(match.params.row))

          return <ByWeek row={row} />
        }}
      />
      <Route
        path={`${match.path}/by-month/:row`}
        render={({ match }) => {
          if (!data) {
            return null
          }

          const row = JSON.parse(decodeURIComponent(match.params.row))

          return <ByMonth row={row} />
        }}
      />
    </>
  )
}
