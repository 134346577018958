import useDetailData from './useDetailData'
import { contactActionColumns } from '../../data/contactaction'

export function useContactActionTermCountyWeekDetailData(
  params: Object,
  cache: Boolean = true
) {
  return useDetailData(
    '/contactaction/term-county-week-detail',
    params,
    contactActionColumns,
    cache
  )
}

export function useContactActionTermMonthDetailData(
  params: Object,
  cache: Boolean = true
) {
  return useDetailData(
    '/contactaction/term-month-detail',
    params,
    contactActionColumns,
    cache
  )
}
