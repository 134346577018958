import React, { useEffect } from 'react'
import { TitleBar, Grid } from '../../../layout'
import { useContactActionTermMonthDetailData } from '../../../../hooks'

type Props = {
  row: Object
}

export default function ByMonth(props: Props) {
  const { row } = props

  const detailData = useContactActionTermMonthDetailData(row, false)

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  if (!row) {
    return 'Loading...'
  }

  if (!detailData) {
    return 'Loading....'
  }

  return (
    <div className="ContactActionDashboard_ByMonth">
      <TitleBar
        backTo="/contactaction/summary"
        text={`${row.date} · ${row.actionServiceTerm}`}
      />
      <div className="content">
        <Grid {...detailData} download />
      </div>
    </div>
  )
}
