import React from 'react'
import { Grid } from '../../../layout'
import { useContactUserDataGridData } from '../../../../hooks'

export default function RawData() {
  const data = useContactUserDataGridData()

  return (
    <div className="ContactUserDataDashboard_RawData">
      {data ? (
        <Grid {...data} download />
      ) : (
        'Loading ContactUserDataDashboard...'
      )}
    </div>
  )
}
