import './SiteDashboard.scss'

import React from 'react'
import { Grid } from '../../layout'
import { useSiteGridData } from '../../../hooks'

function SiteDashboard() {
  const data = useSiteGridData()

  return (
    <div className="SiteDashboard">
      {data ? <Grid {...data} /> : 'Loading SiteDashboard...'}
    </div>
  )
}

export default SiteDashboard
