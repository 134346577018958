import useGridData from './useGridData'

const columns = [
  { key: 'id', label: 'Id' },
  { key: 'transactionId', label: 'Transaction Id' },
  { key: 'clientTransaction', label: 'Client Transaction', type: 'BOOLEAN' },
  { key: 'date', label: 'Date', type: 'DATE' },
  { key: 'totalTime', label: 'Total Time', type: 'DURATION' },
  { key: 'staff', label: 'Staff' },
  { key: 'callerAddress1', label: 'Caller Address1' },
  { key: 'callerCity', label: 'Caller City' },
  { key: 'callerZipCode', label: 'Caller Zip Code' },
  { key: 'callerCounty', label: 'Caller County' },
  { key: 'callerState', label: 'Caller State' },
  { key: 'callerCounty1', label: 'Caller County1' },
  { key: 'callerAge', label: 'Caller Age' },
  { key: 'callerGender', label: 'Caller Gender' },
  { key: 'callerSearchedGender', label: 'Caller Searched Gender' },
  { key: 'callerPhone', label: 'Caller Phone' },
  { key: 'callerPhoneExtension', label: 'Caller Phone Extension' },
  { key: 'callerPhoneNote', label: 'Caller Phone Note' },
  { key: 'callerEmail', label: 'Caller Email' },
  { key: 'callerIsClient', label: 'Caller Is Client', type: 'BOOLEAN' },
  { key: 'callerTransactionType', label: 'Caller Transaction Type' },
  {
    key: 'callerIsHeadOfHousehold',
    label: 'Caller Is Head Of Household',
    type: 'BOOLEAN'
  },
  { key: 'incompleteCall', label: 'Incomplete Call', type: 'BOOLEAN' },
  { key: 'staffId', label: 'Staff Id' },
  { key: 'recordOwner', label: 'Record Owner' },
  { key: 'createdAt', label: 'Created At', type: 'DATETIME' },
  { key: 'updatedAt', label: 'Updated At', type: 'DATETIME' }
]

export default function useContactGridData(cache: Boolean = true) {
  return useGridData('/contact', columns, cache)
}
