import './Home.scss'

import React from 'react'

function Home() {
  return (
    <div className="Home">
      <h2>Home</h2>
      <p>Select a dashboard</p>
    </div>
  )
}

export default Home
