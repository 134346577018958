import './Footer.scss'

import React from 'react'

function Footer() {
  return (
    <div className="Footer">
      <div className="content">Footer</div>
    </div>
  )
}

export default Footer
