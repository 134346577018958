import moment from 'moment'

export function getDuration(timestamp: number) {
  if (typeof timestamp !== 'number') {
    timestamp = Number(timestamp)
  }

  if (isNaN(timestamp) || timestamp < 0) {
    return ''
  }

  const dur = moment.duration(timestamp)

  const hours = Math.floor(dur.asHours())
  if (hours > 0) {
    dur.subtract(hours, 'hours')
  }

  const minutes = Math.floor(dur.asMinutes())
  if (minutes > 0) {
    dur.subtract(minutes, 'minutes')
  }

  const seconds = Math.floor(dur.asSeconds())

  const pad = n => (String(n).length === 1 ? `0${n}` : n)

  let result = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`

  if (result.startsWith('00:0')) {
    result = result.substring(4)
  } else if (result.startsWith('00:')) {
    result = result.substring(3)
  }

  return result
}
