import './App.scss'

import React from 'react'
import Nav from '../layout/nav/Nav'
import Main from '../layout/main/Main'
import Footer from '../layout/footer/Footer'
import Login from '../login/Login'
import { useAuth } from '../../hooks'

export default function App() {
  const user = useAuth()

  return (
    <div className="App">
      <Nav />
      {user === undefined ? (
        <div className="loading">Loading...</div>
      ) : user === null ? (
        <Login />
      ) : (
        <Main />
      )}
      <Footer />
    </div>
  )
}
