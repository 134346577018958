import React, { useEffect } from 'react'
import { TitleBar, Grid } from '../../../layout'
import { useContactActionTermCountyWeekDetailData } from '../../../../hooks'

type Props = {
  row: Object
}

export default function ByWeek(props: Props) {
  const { row } = props

  const detailData = useContactActionTermCountyWeekDetailData(row, false)

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  if (!row) {
    return 'Loading...'
  }

  if (!detailData) {
    return 'Loading....'
  }

  return (
    <div className="ContactActionDashboard_ByWeek">
      <TitleBar
        backTo="/contactaction/summary"
        text={`Week of ${row.date} · ${row.searchCounty} County · ${row.actionServiceTerm}`}
      />
      <div className="content">
        <Grid {...detailData} download />
      </div>
    </div>
  )
}
