// @flow

import './Grid.scss'

import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { getDuration } from '../../../util/numberutil'
import DownloadButton from './DownloadButton'

type Column = {
  key: String,
  label: String,
  type?: 'DATE' | 'DATETIME' | 'PHONE' | null,
  render?: Function
}

type Props = {
  rows: Object[],
  columns?: Column[],
  download: Boolean,
  onRowClick: Function
}

export default function Grid(props: Props) {
  const { rows, columns, download, onRowClick } = props

  const renderHeadCell = (column: Column) => {
    const label = column.label
    return <th key={label}>{label}</th>
  }

  const renderBodyCell = (row: Object, column: Column) => {
    let value = row[column.key]
    switch (column.type) {
      case 'BOOLEAN':
        if (typeof value === 'string') {
          value = value === 'true' ? '✓' : ''
        } else {
          value = !!value ? '✓' : ''
        }
        break
      case 'DATETIME':
      case 'DATE':
        const fmt = column.type === 'DATE' ? 'M/D/Y' : 'M/D/Y h:mm A'
        value = value ? moment(value).format(fmt) : ''
        break
      case 'DURATION':
        if (Number.isFinite(value)) {
          value = getDuration(value * 1000)
        }
        break
      case 'PHONE':
        if (value) {
          value = String(value)
          if (value.length === 10) {
            const parts = [
              value.substring(0, 3),
              value.substring(3, 6),
              value.substring(6)
            ]

            value = `(${parts[0]}) ${parts[1]}-${parts[2]}`
          }
        }
        break
      default:
        if (value !== null && typeof value === 'object') {
          value = JSON.stringify(value)
        }
    }

    return <td key={column.key}>{value}</td>
  }

  const renderRow = (row, i) => {
    const trProps = {
      key: i
    }

    if (onRowClick) {
      trProps.onClick = () => onRowClick(row)
    }

    return (
      <tr {...trProps}>{columns.map(column => renderBodyCell(row, column))}</tr>
    )
  }

  return (
    <div className="Grid">
      <table>
        <thead>
          <tr>{columns.map(renderHeadCell)}</tr>
        </thead>
        <tbody className={classNames({ clickable: !!onRowClick })}>
          {rows.map(renderRow)}
        </tbody>
      </table>
      {download ? <DownloadButton rows={rows} columns={columns} /> : null}
    </div>
  )
}
