export const contactActionColumns = [
  { key: 'id', label: 'Id' },
  { key: 'contactActionId', label: 'Contact Action Id' },
  { key: 'transactionId', label: 'Transaction Id' },
  { key: 'clientId', label: 'Client Id' },
  { key: 'actionDate', label: 'Action Date', type: 'DATETIME' },
  { key: 'staffName', label: 'Staff Name' },
  { key: 'clientFirstName', label: 'Client First Name' },
  { key: 'clientLastName', label: 'Client Last Name' },
  { key: 'clientGender', label: 'Client Gender' },
  { key: 'clientAge', label: 'Client Age' },
  { key: 'clientAddress', label: 'Client Address' },
  { key: 'clientCity', label: 'Client City' },
  { key: 'clientState', label: 'Client State' },
  { key: 'clientZipCode', label: 'Client Zip Code' },
  { key: 'clientCounty', label: 'Client County' },
  { key: 'clientEmail', label: 'Client Email' },
  { key: 'searchCity', label: 'Search City' },
  { key: 'searchState', label: 'Search State' },
  { key: 'searchStateAbbreviation', label: 'Search State Abbreviation' },
  { key: 'searchZipCode', label: 'Search Zip Code' },
  { key: 'searchCounty', label: 'Search County' },
  { key: 'searchGender', label: 'Search Gender' },
  { key: 'searchGenderType', label: 'Search Gender Type' },
  { key: 'searchAge', label: 'Search Age' },
  { key: 'actionType', label: 'Action Type' },
  { key: 'actionServiceTerm', label: 'Action Service Term' },
  { key: 'actionReason', label: 'Action Reason' },
  { key: 'actionServiceType', label: 'Action Service Type' },
  { key: 'actionNote', label: 'Action Note' },
  { key: 'agencyName', label: 'Agency Name' },
  { key: 'siteName', label: 'Site Name' },
  { key: 'siteAddress', label: 'Site Address' },
  { key: 'siteCity', label: 'Site City' },
  { key: 'siteState', label: 'Site State' },
  { key: 'siteZipCode', label: 'Site Zip Code' },
  { key: 'siteCounty', label: 'Site County' },
  { key: 'sitePhone', label: 'Site Phone', type: 'PHONE' },
  { key: 'agencyKey', label: 'Agency Key' },
  { key: 'siteKey', label: 'Site Key' },
  { key: 'serviceSiteId', label: 'Service Site Id' },
  { key: 'staffId', label: 'Staff Id' },
  { key: 'recordOwner', label: 'Record Owner' },
  { key: 'referralUpdated', label: 'Referral Updated', type: 'DATETIME' },
  { key: 'createdAt', label: 'Created At', type: 'DATETIME' },
  { key: 'updatedAt', label: 'Updated At', type: 'DATETIME' }
]
