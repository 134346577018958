import useGridData from './useGridData'

const columns = [
  { key: 'id', label: 'Id' },
  { key: 'agentId', label: 'Agent Id' },
  { key: 'userName', label: 'User Name' },
  { key: 'firstName', label: 'First Name' },
  { key: 'middleName', label: 'Middle Name' },
  { key: 'lastName', label: 'Last Name' },
  { key: 'userID', label: 'User Id' },
  { key: 'emailAddress', label: 'Email Address' },
  { key: 'isActive', label: 'Is Active' },
  { key: 'teamId', label: 'Team Id' },
  { key: 'teamName', label: 'Team Name' },
  { key: 'isBillable', label: 'Is Billable' },
  { key: 'reportToId', label: 'Report To Id' },
  { key: 'reportToName', label: 'Report To Name' },
  { key: 'isSupervisor', label: 'Is Supervisor' },
  { key: 'lastLogin', label: 'Last Login', type: 'DATETIME' },
  { key: 'lastUpdated', label: 'Last Updated' },
  { key: 'location', label: 'Location' },
  { key: 'custom1', label: 'Custom1' },
  { key: 'custom2', label: 'Custom2' },
  { key: 'custom3', label: 'Custom3' },
  { key: 'custom4', label: 'Custom4' },
  { key: 'custom5', label: 'Custom5' },
  { key: 'internalId', label: 'Internal Id' },
  { key: 'profileId', label: 'Profile Id' },
  { key: 'profileName', label: 'Profile Name' },
  { key: 'timeZone', label: 'Time Zone' },
  { key: 'country', label: 'Country' },
  { key: 'countryName', label: 'Country Name' },
  { key: 'state', label: 'State' },
  { key: 'city', label: 'City' },
  { key: 'chatRefusalTimeout', label: 'Chat Refusal Timeout' },
  { key: 'phoneRefusalTimeout', label: 'Phone Refusal Timeout' },
  { key: 'workItemRefusalTimeout', label: 'Work Item Refusal Timeout' },
  { key: 'defaultDialingPattern', label: 'Default Dialing Pattern' },
  { key: 'defaultDialingPatternName', label: 'Default Dialing Pattern Name' },
  { key: 'useTeamMaxConcurrentChats', label: 'Use Team Max Concurrent Chats' },
  { key: 'maxConcurrentChats', label: 'Max Concurrent Chats' },
  { key: 'notes', label: 'Notes' },
  { key: 'createDate', label: 'Create Date' },
  { key: 'inactiveDate', label: 'Inactive Date' },
  { key: 'hireDate', label: 'Hire Date' },
  { key: 'terminationDate', label: 'Termination Date' },
  { key: 'hourlyCost', label: 'Hourly Cost' },
  { key: 'rehireStatus', label: 'Rehire Status' },
  { key: 'employmentType', label: 'Employment Type' },
  { key: 'employmentTypeName', label: 'Employment Type Name' },
  { key: 'referral', label: 'Referral' },
  { key: 'atHome', label: 'At Home' },
  { key: 'hiringSource', label: 'Hiring Source' },
  { key: 'ntLoginName', label: 'Nt Login Name' },
  { key: 'scheduleNotification', label: 'Schedule Notification' },
  { key: 'federatedId', label: 'Federated Id' },
  {
    key: 'useTeamEmailAutoParkingLimit',
    label: 'Use Team Email Auto Parking Limit'
  },
  { key: 'maxEmailAutoParkingLimit', label: 'Max Email Auto Parking Limit' },
  { key: 'sipUser', label: 'Sip User' },
  { key: 'systemUser', label: 'System User' },
  { key: 'systemDomain', label: 'System Domain' },
  { key: 'crmUserName', label: 'Crm User Name' },
  { key: 'useAgentTimeZone', label: 'Use Agent Time Zone' },
  { key: 'timeDisplayFormat', label: 'Time Display Format' },
  { key: 'sendEmailNotifications', label: 'Send Email Notifications' },
  { key: 'apiKey', label: 'Api Key' },
  { key: 'telephone1', label: 'Telephone1' },
  { key: 'telephone2', label: 'Telephone2' },
  { key: 'userType', label: 'User Type' },
  { key: 'isWhatIfAgent', label: 'Is What If Agent' },
  { key: 'timeZoneOffset', label: 'Time Zone Offset' },
  { key: 'requestContact', label: 'Request Contact' },
  { key: 'chatThreshold', label: 'Chat Threshold' },
  { key: 'useTeamChatThreshold', label: 'Use Team Chat Threshold' },
  { key: 'emailThreshold', label: 'Email Threshold' },
  { key: 'useTeamEmailThreshold', label: 'Use Team Email Threshold' },
  { key: 'workItemThreshold', label: 'Work Item Threshold' },
  { key: 'useTeamWorkItemThreshold', label: 'Use Team Work Item Threshold' },
  { key: 'contactAutoFocus', label: 'Contact Auto Focus' },
  { key: 'useTeamContactAutoFocus', label: 'Use Team Contact Auto Focus' },
  { key: 'useTeamRequestContact', label: 'Use Team Request Contact' },
  { key: 'subject', label: 'Subject' },
  { key: 'issuer', label: 'Issuer' },
  { key: 'recordingNumbers', label: 'Recording Numbers' },
  { key: 'isOpenIdProfileComplete', label: 'Is Open Id Profile Complete' },
  { key: 'teamUuId', label: 'Team Uu Id' },
  { key: 'maxPreview', label: 'Max Preview' },
  { key: 'deliveryMode', label: 'Delivery Mode' },
  { key: 'totalContactCount', label: 'Total Contact Count' },
  {
    key: 'useTeamDeliveryModeSettings',
    label: 'Use Team Delivery Mode Settings'
  },
  { key: 'emailRefusalTimeout', label: 'Email Refusal Timeout' },
  { key: 'voicemailRefusalTimeout', label: 'Voicemail Refusal Timeout' },
  { key: 'smsRefusalTimeout', label: 'Sms Refusal Timeout' },
  { key: 'agentvoiceThreshold', label: 'Agentvoice Threshold' },
  { key: 'agentchatThreshold', label: 'Agentchat Threshold' },
  { key: 'agentemailThreshold', label: 'Agentemail Threshold' },
  { key: 'agentworkItemThreshold', label: 'Agentwork Item Threshold' },
  { key: 'agentDeliveryMode', label: 'Agent Delivery Mode' },
  { key: 'agentTotalContactCount', label: 'Agent Total Contact Count' },
  { key: 'agentRequestContact', label: 'Agent Request Contact' },
  { key: 'agentContactAutoFocus', label: 'Agent Contact Auto Focus' },
  { key: 'useTeamVoiceThreshold', label: 'Use Team Voice Threshold' },
  { key: 'voiceThreshold', label: 'Voice Threshold' },
  { key: 'createdAt', label: 'Created At', type: 'DATETIME' },
  { key: 'updatedAt', label: 'Updated At', type: 'DATETIME' }
]

export default function useAgentGridData(cache: Boolean = true) {
  return useGridData('/agent', columns, cache)
}
