import useGridData from './useGridData'

const columns = [
  { key: 'id', label: 'Id' },
  { key: 'transactionId', label: 'Transaction Id' },
  { key: 'updatedAt', label: 'Date', type: 'DATE' },
  { key: 'recordOwner', label: 'Record Owner' },
  { key: 'createdAt', label: 'Created At', type: 'DATETIME' }
]

export default function useContactUserDataGridData(cache: Boolean = true) {
  return useGridData('/contactuserdata', columns, cache)
}
