import './Nav.scss'

import React from 'react'
import logo from '../../../assets/logo.jpg'

function Nav() {
  return (
    <nav className="Nav">
      <div className="row-c-s">
        <img src={logo} className="logo" alt="AUW" />
        <h1>AUW Dashboards</h1>
      </div>
      <div className="row-c">...</div>
    </nav>
  )
}

export default Nav
