import './SkillDashboard.scss'

import React from 'react'
import { Grid } from '../../layout'
import { useSkillGridData } from '../../../hooks'

function SkillDashboard() {
  const data = useSkillGridData()

  return (
    <div className="SkillDashboard">
      {data ? <Grid {...data} /> : 'Loading SkillDashboard...'}
    </div>
  )
}

export default SkillDashboard
