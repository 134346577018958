// @flow

import './TitleBar.scss'

import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  backTo: String,
  text: String
}

export default function TitleBar(props: Props) {
  const { backTo, text } = props

  return (
    <div className="TitleBar">
      <div className="back">
        <Link to={backTo}>
          <svg viewBox="0 0 40 40" width={40} height={40}>
            <path d="M 18 10 L 7 20 L 18 30 M 7 20 L 30 20" strokeWidth={2} />
          </svg>
        </Link>
      </div>
      <div className="title">{text}</div>
    </div>
  )
}
