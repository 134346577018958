import useGridData from './useGridData'

const columns = [
  { key: 'id', label: 'Id' },
  { key: 'skillId', label: 'Skill Id' },
  { key: 'skillName', label: 'Skill Name' },
  { key: 'mediaTypeId', label: 'Media Type Id' },
  { key: 'mediaTypeName', label: 'Media Type Name' },
  { key: 'isActive', label: 'Is Active' },
  { key: 'campaignId', label: 'Campaign Id' },
  { key: 'campaignName', label: 'Campaign Name' },
  { key: 'notes', label: 'Notes' },
  { key: 'acwTypeId', label: 'Acw Type Id' },
  { key: 'stateIdACW', label: 'State Id Acw' },
  { key: 'stateNameACW', label: 'State Name Acw' },
  { key: 'maxSecondsACW', label: 'Max Seconds Acw' },
  { key: 'acwPostTimeoutStateId', label: 'Acw Post Timeout State Id' },
  { key: 'acwPostTimeoutStateName', label: 'Acw Post Timeout State Name' },
  { key: 'requireDisposition', label: 'Require Disposition' },
  { key: 'allowSecondaryDisposition', label: 'Allow Secondary Disposition' },
  { key: 'scriptDisposition', label: 'Script Disposition' },
  { key: 'agentRestTime', label: 'Agent Rest Time' },
  { key: 'makeTranscriptAvailable', label: 'Make Transcript Available' },
  { key: 'transcriptFromAddress', label: 'Transcript From Address' },
  { key: 'displayThankyou', label: 'Display Thankyou' },
  { key: 'thankYouLink', label: 'Thank You Link' },
  { key: 'popThankYou', label: 'Pop Thank You' },
  { key: 'popThankYouURL', label: 'Pop Thank You Url' },
  { key: 'isOutbound', label: 'Is Outbound' },
  { key: 'outboundStrategy', label: 'Outbound Strategy' },
  { key: 'isRunning', label: 'Is Running' },
  { key: 'priorityBlending', label: 'Priority Blending' },
  { key: 'callerIdOverride', label: 'Caller Id Override' },
  { key: 'scriptId', label: 'Script Id' },
  { key: 'scriptName', label: 'Script Name' },
  { key: 'emailFromAddress', label: 'Email From Address' },
  { key: 'emailFromEditable', label: 'Email From Editable' },
  { key: 'emailBccAddress', label: 'Email Bcc Address' },
  { key: 'emailParking', label: 'Email Parking' },
  { key: 'chatWarningThreshold', label: 'Chat Warning Threshold' },
  { key: 'agentTypingIndicator', label: 'Agent Typing Indicator' },
  { key: 'patronTypingPreview', label: 'Patron Typing Preview' },
  { key: 'interruptible', label: 'Interruptible' },
  { key: 'callSuppressionScriptId', label: 'Call Suppression Script Id' },
  { key: 'reskillHours', label: 'Reskill Hours' },
  { key: 'reskillHoursName', label: 'Reskill Hours Name' },
  { key: 'countReskillHours', label: 'Count Reskill Hours' },
  { key: 'minWFIAgents', label: 'Min Wfi Agents' },
  { key: 'useScreenPops', label: 'Use Screen Pops' },
  { key: 'screenPopTriggerEvent', label: 'Screen Pop Trigger Event' },
  { key: 'useCustomScreenPops', label: 'Use Custom Screen Pops' },
  { key: 'screenPopDetail', label: 'Screen Pop Detail' },
  { key: 'minWorkingTime', label: 'Min Working Time' },
  { key: 'agentless', label: 'Agentless' },
  { key: 'agentlessPorts', label: 'Agentless Ports' },
  { key: 'initialPriority', label: 'Initial Priority' },
  { key: 'acceleration', label: 'Acceleration' },
  { key: 'maxPriority', label: 'Max Priority' },
  { key: 'serviceLevelThreshold', label: 'Service Level Threshold' },
  { key: 'serviceLevelGoal', label: 'Service Level Goal' },
  { key: 'enableShortAbandon', label: 'Enable Short Abandon' },
  { key: 'shortAbandonThreshold', label: 'Short Abandon Threshold' },
  { key: 'countShortAbandons', label: 'Count Short Abandons' },
  { key: 'countOtherAbandons', label: 'Count Other Abandons' },
  { key: 'messageTemplateId', label: 'Message Template Id' },
  { key: 'smsTransportCodeId', label: 'Sms Transport Code Id' },
  { key: 'smsTransportCode', label: 'Sms Transport Code' },
  {
    key: 'deliverMultipleNumbersSerially',
    label: 'Deliver Multiple Numbers Serially'
  },
  { key: 'cradleToGrave', label: 'Cradle To Grave' },
  { key: 'priorityInterrupt', label: 'Priority Interrupt' },
  { key: 'createdAt', label: 'Created At', type: 'DATETIME' },
  { key: 'updatedAt', label: 'Updated At', type: 'DATETIME' }
]

export default function useSkillGridData(cache: Boolean = true) {
  return useGridData('/skill', columns, cache)
}
