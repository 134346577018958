import './Summary.scss'

import React from 'react'
import { useMessageSummaryData } from '../../../../hooks'
import { Card, Grid } from '../../../layout'

const weekColumns = [
  { key: 'date', label: 'Week start date' },
  { key: 'count', label: 'Count' }
]

export default function Summary() {
  const data = useMessageSummaryData()

  return (
    <div className="MessageDashboard_Summary">
      {data ? (
        <>
          <Card title="Total records grouped by week">
            <Grid columns={weekColumns} rows={data.totalsByWeek} />
          </Card>
        </>
      ) : (
        'Loading...'
      )}
    </div>
  )
}
