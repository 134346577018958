import './index.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { HashRouter as Router } from 'react-router-dom'
import App from './components/app/App'

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || '/api'
axios.defaults.withCredentials = true

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
