import './MessageDashboard.scss'

import React, { useState } from 'react'
import { useRouteMatch, Redirect, NavLink, Route } from 'react-router-dom'
import { Tabs } from '../../layout'
import * as Tab from './tabs'
import { TabDownloadButtonContext } from '../context'

export default function MessageDashboard() {
  const match = useRouteMatch()
  const [downloadButton, setDownloadButton] = useState(null)

  return (
    <TabDownloadButtonContext.Provider value={setDownloadButton}>
      <div className="MessageDashboard">
        <div className="row">
          <Tabs>
            <NavLink to={`${match.url}/summary`}>Summary</NavLink>
            <NavLink to={`${match.url}/data`}>Raw Data</NavLink>
            <div className="divider" />
            {downloadButton}
          </Tabs>
        </div>

        <div className="content">
          <Route exact path={match.path}>
            <Redirect to={`${match.url}/summary`} />
          </Route>
          <Route path={`${match.url}/summary`}>
            <Tab.Summary />
          </Route>
          <Route path={`${match.url}/data`}>
            <Tab.RawData />
          </Route>
        </div>
      </div>
    </TabDownloadButtonContext.Provider>
  )
}
