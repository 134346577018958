import querystring from 'querystring'
import { useState, useEffect } from 'react'
import axios from 'axios'

export let _cachedData = {}

export default function useDetailData(
  endpoint: string,
  params: Object,
  columns: Object[],
  cache: Boolean = true
) {
  const [data, setData] = useState(null)

  useEffect(() => {
    if (!params) {
      return
    }

    if (cache && _cachedData[endpoint]) {
      setData(_cachedData[endpoint])
    } else {
      const url = endpoint + '?' + querystring.stringify(params)

      axios.get(url).then(res => {
        const data = { rows: res.data, columns }
        setData(data)
        if (cache) _cachedData[endpoint] = data
      })
    }
  }, [endpoint, params, columns, cache])

  return data
}
