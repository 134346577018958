// @flow

import './Tabs.scss'

import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'

type Props = {
  scroll: Boolean,
  children: any
}

export default function Tabs(props: Props) {
  const ref = useRef()

  const scroll = (forward: boolean) => {
    const node: HTMLDivElement = ref.current
    const rect = node.getBoundingClientRect()
    let amount = rect.width * 0.3
    if (!forward) {
      amount *= -1
    }
    node.scrollTo({ left: node.scrollLeft + amount, behavior: 'smooth' })
  }

  return (
    <div className="Tabs">
      <div className="scroll-area" ref={ref}>
        {props.scroll ? <div className="end-cap" /> : null}
        {props.children}
        {props.scroll ? <div className="end-cap" /> : null}
      </div>
      {props.scroll ? (
        <>
          <div className="scroll-buttons">
            <button className="scroll left" onClick={() => scroll(false)}>
              <svg viewBox="0 0 10 10" width={10} height={10}>
                <path d="M 6.5 2 L 3.5 5 L 6.5 8" />
              </svg>
            </button>
            <button className="scroll right" onClick={() => scroll(true)}>
              <svg viewBox="0 0 10 10" width={10} height={10}>
                <path d="M 3.5 2 L 6.5 5 L 3.5 8" />
              </svg>
            </button>
          </div>
        </>
      ) : null}
    </div>
  )
}

Tabs.defaultProps = {
  scroll: false
}

export function Tab({ children, ...props }) {
  return <NavLink {...props}>{children}</NavLink>
}

export function RTMTab({ children, ...props }) {
  return (
    <NavLink {...props}>
      {children} <small className="rtm">RTM</small>
    </NavLink>
  )
}

export function InContactTab({ children, ...props }) {
  return (
    <NavLink {...props}>
      {children} <small className="incontact">InContact</small>
    </NavLink>
  )
}
