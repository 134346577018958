import './Summary.scss'

import React from 'react'
import { useContactSummaryData } from '../../../../hooks'
import { Card, Grid } from '../../../layout'

const monthColumns = [
  { key: 'date', label: 'Date' },
  { key: 'count', label: 'Count' }
]

const monthGenderColumns = [
  { key: 'date', label: 'Date' },
  { key: 'gender', label: 'Gender' },
  { key: 'count', label: 'Count' }
]

const monthAgeColumns = [
  { key: 'date', label: 'Date' },
  { key: 'age', label: 'Age range' },
  { key: 'count', label: 'Count' }
]

const ageColumns = [
  { key: 'age', label: 'Age range' },
  { key: 'count', label: 'Count' }
]

const zipColumns = [
  { key: 'zipCode', label: 'Zip code' },
  { key: 'count', label: 'Count' }
]

export default function Summary() {
  const data = useContactSummaryData()

  return (
    <div className="ContactDashboard_Summary">
      {data ? (
        <>
          <Card title="Total records grouped by month">
            <Grid columns={monthColumns} rows={data.totalsByMonth} />
          </Card>
          <Card title="Total records grouped by month and gender">
            <Grid
              columns={monthGenderColumns}
              rows={data.totalsByMonthByGender}
            />
          </Card>
          <Card title="Total records grouped by month and age">
            <Grid columns={monthAgeColumns} rows={data.totalsByMonthByAge} />
          </Card>
          <Card title="Total records grouped by age">
            <Grid columns={ageColumns} rows={data.totalsByAge} />
          </Card>
          <Card title="Total records grouped by zip code">
            <Grid columns={zipColumns} rows={data.totalsByZip} />
          </Card>
        </>
      ) : (
        'Loading...'
      )}
    </div>
  )
}
