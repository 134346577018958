import { useState, useEffect } from 'react'
import axios from 'axios'

export default function useGet(endpoint: string) {
  const [data, setData] = useState()

  useEffect(() => {
    axios
      .get(endpoint)
      .then(res => {
        setData(res.data)
      })
      .catch(err => {
        console.error(err)
        setData(null)
      })
  }, [endpoint])

  return data
}
