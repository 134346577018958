import './MediaTypeDashboard.scss'

import React from 'react'
import { Grid } from '../../layout'
import { useMediaTypeGridData } from '../../../hooks'

function MediaTypeDashboard() {
  const data = useMediaTypeGridData()

  return (
    <div className="MediaTypeDashboard">
      {data ? <Grid {...data} /> : 'Loading MediaTypeDashboard...'}
    </div>
  )
}

export default MediaTypeDashboard
