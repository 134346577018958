import React from 'react'
import { Grid } from '../../../layout'
import { useContactActionGridData } from '../../../../hooks'

export default function RawData() {
  const data = useContactActionGridData()

  return (
    <div className="ContactActionDashboard_RawData">
      {data ? <Grid {...data} download /> : 'Loading ContactActionDashboard...'}
    </div>
  )
}
