import React from 'react'
import { Grid } from '../../../layout'
import { useContactGridData } from '../../../../hooks'

export default function RawData() {
  const data = useContactGridData()

  return (
    <div className="ContactDashboard_RawData">
      {data ? <Grid {...data} download /> : 'Loading ContactDashboard...'}
    </div>
  )
}
