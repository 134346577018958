import './AgentDashboard.scss'

import React from 'react'
import { Grid } from '../../layout'
import { useAgentGridData } from '../../../hooks'

function AgentDashboard() {
  const data = useAgentGridData()

  return (
    <div className="AgentDashboard">
      {data ? <Grid {...data} /> : 'Loading AgentDashboard...'}
    </div>
  )
}

export default AgentDashboard
